/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\meatPatty.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_meatPatty: THREE.Mesh;
    Mesh_meatPatty_1: THREE.Mesh;
  };
  materials: {
    brownDark: THREE.MeshBasicMaterial;
    brownDarkest: THREE.MeshBasicMaterial;
  };
};

export function MeatPatty(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/meatPatty.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Mesh_meatPatty.geometry}
        material={materials.brownDark}
      />
      <mesh
        geometry={nodes.Mesh_meatPatty_1.geometry}
        material={materials.brownDarkest}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/food/meatPatty.glb");
