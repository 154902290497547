import { a, useSpring } from "@react-spring/three";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { Group } from "three";

interface RotatorProps extends React.ComponentPropsWithoutRef<"group"> {
  index: number;
  total: number;
  scaleEnabled?: boolean;
  speed?: number;
}

export default function Rotator({
  speed = Math.sign(Math.random() - 0.5) * (0.1 + Math.random() * 1),
  scaleEnabled = true,
  index,
  total,
  ...props
}: RotatorProps) {
  const rotatorRef = useRef<Group>(null!);
  const mySpeed = useRef(speed);

  useFrame((_, delta) => {
    rotatorRef.current.rotation.y += delta * mySpeed.current;
  });

  const { positionY } = useSpring({
    positionY: (total / 2 - index) * 0.1,
  });
  const [{ scale }, scaleApi] = useSpring(() => ({
    from: { scale: 1 },
  }));

  useEffect(() => {
    if (scaleEnabled) {
      void scaleApi.start({
        from: { scale: 1.3 },
        to: { scale: 1.0 },
      });
    }
  }, [scaleApi, props.children, scaleEnabled]);

  return (
    <a.group {...props} scale={scale} position-y={positionY} ref={rotatorRef} />
  );
}
