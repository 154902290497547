uniform sampler2D uTexture;
uniform vec3 uColor;

void main(){
	vec2 uv = gl_PointCoord;

	vec4 color = texture2D(uTexture, uv);

	gl_FragColor = vec4(uColor, color.r);
	#include <tonemapping_fragment>
	#include <colorspace_fragment>
}