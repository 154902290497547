/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\public\assets\models\City.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useEffect, useRef } from "react";
import { useControls } from "leva";
import { MatrixMaterial } from "../materials/MatrixMaterial";
import { useFrame } from "@react-three/fiber";

interface GLTFResult extends GLTF {
  nodes: {
    Plane002: THREE.Mesh;
  };
  materials: object;
}

export default function City(props: JSX.IntrinsicElements["group"]) {
  const { nodes } = useGLTF("/assets/models/City.glb") as GLTFResult;

  const speed = useRef<number>(0);

  const materialRef = useRef<MatrixMaterial>(null!);
  const cityRef = useRef<THREE.Mesh>(null!);

  const { color } = useControls("Matrix City", {
    color: { value: "#db1fff" },
  });

  useFrame((_, delta) => {
    const speedTarget = 0.6;

    if (materialRef.current.uniforms.uTime.value < 10) speed.current = 30;

    speed.current += (speedTarget - speed.current) * Math.min(delta * 1.4, 1);
    materialRef.current.uniforms.uTime.value += delta * speed.current;
  });

  useEffect(() => {
    speed.current = 30;
    // cityRef.current.geometry.boundingBox = new THREE.Box3(
    //   new THREE.Vector3(0, 0, 0),
    //   new THREE.Vector3(1, 1, 1),
    // );
    // cityRef.current.frustumCulled = true;
    // cityRef.current.geometry.boundingBox = new THREE.Box3(
    //   new THREE.Vector3(0, 0, 0),
    //   new THREE.Vector3(0.00001, 0.00001, 0.00001),
    // );
  }, []);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane002.geometry} ref={cityRef}>
        <matrixMaterial color={color} ref={materialRef} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/assets/models/City.glb");
