import React from "react";
import { create } from "zustand";

interface FoodAttributes {
  foods: React.ReactNode[];
  amount: number;
  indexes: number[];
}

interface FoodActions {
  setFoods(this: void, foods: React.ReactNode[]): void;
  addFood(this: void): void;
  removeFood(this: void): void;
  next(this: void, index: number): void;
  previous(this: void, index: number): void;
}

const useFood = create<FoodAttributes & FoodActions>((set) => ({
  foods: [],
  amount: 0,
  indexes: [],

  setFoods(foods) {
    set(() => ({ foods: foods }));
  },

  addFood() {
    set((state) => {
      if (state.amount >= 3) return {};

      const newIndexes = state.indexes;
      newIndexes.push(0);

      return { amount: state.amount + 1, indexes: [...newIndexes] };
    });
  },

  removeFood() {
    set((state) => {
      if (state.amount === 0) return {};

      const newIndexes = state.indexes;
      newIndexes.pop();

      return { amount: state.amount - 1, indexes: [...newIndexes] };
    });
  },

  next(index) {
    set((state) => {
      if (index >= state.amount) return {};

      const newIndexes = state.indexes;
      newIndexes[index] = newIndexes[index] =
        (newIndexes[index] + 1) % state.foods.length;

      return {
        indexes: [...newIndexes],
      };
    });
  },

  previous(index) {
    set((state) => {
      if (index >= state.amount) return {};

      const newIndexes = state.indexes;
      newIndexes[index] =
        (newIndexes[index] - 1 + state.foods.length) % state.foods.length;

      return {
        indexes: [...newIndexes],
      };
    });
  },
}));

export default useFood;
