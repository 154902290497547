import { useTranslation } from "react-i18next";
import { FaGithub, FaLinkedin, FaItchIo } from "react-icons/fa";

export default function Footer() {
  const [t] = useTranslation();
  return (
    <>
      <div className="relative -top-10 z-30 mx-auto self-start md:top-2">
        <a
          className="-fuchsia-700 rounded-full border-4 border-fuchsia-700 bg-stone-900 px-8 py-4 text-xl text-fuchsia-700 no-underline transition-colors duration-300 hover:border-stone-200 hover:bg-fuchsia-800 hover:text-stone-200 focus:border-stone-200 focus:bg-fuchsia-800 focus:text-stone-200 md:text-4xl"
          href="mailto:info@williamarnone.com"
        >
          {t("call to action")}
        </a>
      </div>
      <footer className="z-20 my-2 flex h-fit flex-col items-start justify-around gap-4 bg-gradient-to-t from-stone-900 from-80% px-2 prose-a:text-stone-200 prose-a:no-underline md:flex-row md:items-end md:p-12">
        <div className="text-md flex h-fit flex-col justify-around gap-1 md:gap-2 md:text-xl">
          <a href="https://www.linkedin.com/in/william-arnone/">
            <div className="flex h-fit items-center gap-2">
              <FaLinkedin />
              Linkedin
            </div>
          </a>
          <a href="https://github.com/WilliamArnone">
            <div className="flex h-fit items-center gap-2">
              <FaGithub />
              Github
            </div>
          </a>
          <a href="https://will-14m.itch.io/">
            <div className="flex h-fit items-center gap-2">
              <FaItchIo />
              Itch.io
            </div>
          </a>
        </div>
        <div className="flex flex-col text-start text-sm md:self-end md:text-end md:text-xl">
          <span>All rights reserved</span>
          <span>©2024 William Arnone Official Website</span>
        </div>
      </footer>
    </>
  );
}
