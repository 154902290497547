/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\bread.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_bread: THREE.Mesh;
    Mesh_bread_1: THREE.Mesh;
  };
  materials: {
    brown: THREE.MeshBasicMaterial;
    brownLight: THREE.MeshBasicMaterial;
  };
};

export function Bread(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/bread.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Mesh_bread.geometry} material={materials.brown} />
      <mesh
        geometry={nodes.Mesh_bread_1.geometry}
        material={materials.brownLight}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/food/bread.glb");
