import { Trans } from "react-i18next";
import AnimatedParagraph from "./AnimatedParagraph";
import { Justification } from "../consts";
import LastScreen from "./LastScreen";
import ConfiguratorScreen from "./ConfiguratorScreen";

export default function PageContent() {
  return (
    <>
      <AnimatedParagraph right justification={Justification.BOT}>
        <Trans i18nKey={"description"} />
        <br />
        <strong className="w-fit">
          <Trans i18nKey={"slogan"} />
        </strong>
      </AnimatedParagraph>

      <AnimatedParagraph>
        <Trans i18nKey={"interactive desc"} />
      </AnimatedParagraph>
      <AnimatedParagraph right>
        <Trans i18nKey={"immersive desc"} />
      </AnimatedParagraph>
      <AnimatedParagraph>
        <Trans i18nKey={"innovative desc"} />
      </AnimatedParagraph>

      <ConfiguratorScreen />

      <LastScreen />
    </>
  );
}
