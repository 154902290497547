import { Canvas } from "@react-three/fiber";
import Experience from "@components/three/Experience";
import { Leva } from "leva";
import { Perf } from "r3f-perf";
import Header from "./components/dom/Header";
import PageContent from "./components/dom/PageContent";
import i18n from "./locales/i18n";

const DEBUG = window.location.hash === "#debug";
document.documentElement.lang = i18n.language;

function App() {
  return (
    <>
      <Leva oneLineLabels collapsed hidden={!DEBUG} />

      <Header />
      <PageContent />
      <div className="not-prose fixed left-0 top-0 z-10 m-0 h-lvh w-full p-0">
        <Canvas>
          <Experience />
          {DEBUG && <Perf position={"bottom-right"} />}
        </Canvas>
      </div>
    </>
  );
}

export default App;
