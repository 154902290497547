import useFood from "@/stores/useFood";
//import { Bacon } from "../models/FoodConfigurator/Bacon";
import { CheeseCut } from "../models/FoodConfigurator/CheeseCut";
import { EggCooked } from "../models/FoodConfigurator/EggCooked";
import { MeatPatty } from "../models/FoodConfigurator/MeatPatty";
import { MushroomHalf } from "../models/FoodConfigurator/MushroomHalf";
import { PaprikaSlice } from "../models/FoodConfigurator/PaprikaSlice";
import { TomatoSlice } from "../models/FoodConfigurator/TomatoSlice";
import { Bread } from "../models/FoodConfigurator/Bread";
import FoodWrapper from "./FoodWrapper";
import { useMemo } from "react";
import Rotator from "../models/Rotator";

useFood.getState().setFoods([
  // <Bacon key={"bacon"} />,
  <MeatPatty key={"meat"} />,
  <MushroomHalf
    position-z={-0.05}
    rotation-x={Math.PI / 2}
    rotation-y={Math.PI / 2}
    key={"mushroom"}
  />,
  <PaprikaSlice scale={[2, 1, 2]} key={"paprika"} />,
  <TomatoSlice scale={[2, 1, 2]} key={"tomato"} />,
  <EggCooked key={"egg"} />,
  <CheeseCut key={"cheese"} />,
]);

export default function FoodConfigurator() {
  const foods = useFood((state) => state.foods);
  const amount = useFood((state) => state.amount);
  const indexes = useFood((state) => state.indexes);

  const ingredients = useMemo(() => {
    const ingredients = [];

    for (let i = 0; i < amount; i++) {
      ingredients.push(foods[indexes[i]]);
    }

    return ingredients;
  }, [foods, amount, indexes]);

  return (
    <group position={[0, -0.0, 4]} rotation-x={0.2}>
      <Rotator index={0} total={amount} speed={-0.1} scaleEnabled={false}>
        <Bread />
      </Rotator>

      <FoodWrapper>{ingredients}</FoodWrapper>

      <Rotator
        index={amount + 1}
        total={amount}
        speed={-0.1}
        scaleEnabled={false}
      >
        <Bread />
      </Rotator>
    </group>
  );
}
