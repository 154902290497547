/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\paprikaSlice.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    paprikaSlice: THREE.Mesh;
  };
  materials: {
    red: THREE.MeshBasicMaterial;
  };
};

export function PaprikaSlice(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/paprikaSlice.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.paprikaSlice.geometry} material={materials.red} />
    </group>
  );
}

useGLTF.preload("/assets/models/food/paprikaSlice.glb");
