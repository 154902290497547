import { create } from "zustand";

export type Piece = "o" | "x";
export type Board = Piece | "";

interface GameAttributes {
  phase: "pc" | "player" | "end";
  lastWinner: "pc" | "player";
  winningPlaces: { x: number; y: number }[];
  player: Piece;
  board: Board[][];
}
interface GameActions {
  select(this: void, x: number, y: number): void;
  compute(): void;
  reset(): void;
}

const startBoard = (pcStart: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const board = [...Array(3)].map(() => [...Array(3)].map(() => "") as Board[]);

  if (pcStart) {
    pcMove(board, "x");
  }

  return board;
};

const pcMove = (board: Board[][], pcPiece: Piece) => {
  const x = Math.floor(Math.random() * 3);
  const y = Math.floor(Math.random() * 3);
  if (board[x][y] === "") board[x][y] = pcPiece;
  else pcMove(board, pcPiece);

  return board;
};

const checkWinner = (board: Board[][]) => {
  for (let x = 0; x < 3; x++) {
    const piece = board[x][0];
    if (piece === "") continue;
    for (let y = 1; y < 3; y++) {
      if (piece !== board[x][y]) break;
      if (y === 2)
        return [
          { x: x, y: 0 },
          { x: x, y: 1 },
          { x: x, y: 2 },
        ];
    }
  }

  for (let y = 0; y < 3; y++) {
    const piece = board[0][y];
    if (piece === "") continue;
    for (let x = 1; x < 3; x++) {
      if (piece !== board[x][y]) break;
      if (x === 2)
        return [
          { x: 0, y: y },
          { x: 1, y: y },
          { x: 2, y: y },
        ];
    }
  }

  if (
    board[0][0] !== "" &&
    board[0][0] === board[1][1] &&
    board[0][0] === board[2][2]
  )
    return [
      { x: 0, y: 0 },
      { x: 1, y: 1 },
      { x: 2, y: 2 },
    ];

  if (
    board[2][0] !== "" &&
    board[2][0] === board[1][1] &&
    board[2][0] === board[0][2]
  )
    return [
      { x: 2, y: 0 },
      { x: 1, y: 1 },
      { x: 0, y: 2 },
    ];

  return [];
};

const keepPlay = (board: Board[][]) => {
  for (let x = 0; x < 3; x++) {
    for (let y = 0; y < 3; y++) {
      if (board[x][y] === "") return true;
    }
  }
  false;
};

const useTicTacToe = create<GameAttributes & GameActions>((set) => ({
  phase: "player",
  lastWinner: "pc",
  winningPlaces: [],
  player: "o",
  board: startBoard(true),
  select(x, y) {
    set((state) => {
      if (state.phase !== "player") return {};
      if (state.board[x][y] !== "") return {};

      const newBoard = state.board;
      newBoard[x][y] = state.player;
      const winnerMove = checkWinner(newBoard);

      //Player has won
      if (winnerMove.length > 0) {
        //restart
        setTimeout(() => {
          state.reset();
        }, 3500);

        return {
          phase: "end",
          lastWinner: "player",
          winningPlaces: winnerMove,
          board: newBoard,
        };
      }

      setTimeout(() => {
        if (keepPlay(newBoard)) state.compute();
        else state.reset();
      }, 1000);

      return {
        phase: "pc",
        board: newBoard,
      };
    });
  },

  compute() {
    set((state) => {
      const newBoard = pcMove(state.board, state.player === "o" ? "x" : "o");
      const winnerMove = checkWinner(newBoard);

      //PC has won
      if (winnerMove.length > 0) {
        //restart
        setTimeout(() => {
          state.reset();
        }, 3500);

        return {
          phase: "end",
          lastWinner: "pc",
          winningPlaces: winnerMove,
          board: newBoard,
        };
      }

      if (keepPlay(newBoard)) {
        return {
          phase: "player",
          board: newBoard,
        };
      } else {
        setTimeout(() => {
          state.reset();
        }, 1000);

        return {
          phase: "end",
          board: newBoard,
        };
      }
    });
  },

  reset() {
    set((state) => ({
      phase: "player",
      player: state.player === "o" ? "x" : "o",
      board: startBoard(state.player === "x"),
      winningPlaces: [],
    }));
  },
}));

export default useTicTacToe;
