export default function Header() {
  return (
    <div className="fixed z-50 h-56 w-full select-none bg-gradient-to-b from-stone-900 from-40%">
      <div className="grid grid-cols-5 p-10">
        <div className="col-span-5 text-3xl md:text-4xl lg:text-6xl">
          William Arnone
        </div>
        <div className="col-span-4 col-start-2">
          <strong className="ld:text-4xl relative -top-1 text-xl md:-top-4 md:text-2xl lg:-top-6 lg:text-4xl">
            CREATIVE DEVELOPER
          </strong>
        </div>
      </div>
    </div>
  );
}
