/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\Panel.glb --types 
Author: Unity Fan youtube channel (https://sketchfab.com/unityfan777)
License: SKETCHFAB Standard (https://sketchfab.com/licenses)
Source: https://sketchfab.com/3d-models/free-scifi-panel-001-public-domain-cc0-189ed1b176f04852a8126961601f95c8
Title: FREE SciFi Panel 001 - public domain (CC0)
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import React from "react";

export type PanelGLTFResult = GLTF & {
  nodes: {
    SciFi_Panel_001_plasticBlack_0: THREE.Mesh;
    SciFi_Panel_001_metal_0: THREE.Mesh;
    SciFi_Panel_001_lightYellow_0: THREE.Mesh;
  };
  materials: {
    plasticBlack: THREE.MeshStandardMaterial;
    metal: THREE.MeshStandardMaterial;
    lightYellow: THREE.MeshStandardMaterial;
  };
};

export interface PanelMeshes {
  Metal: React.ForwardRefExoticComponent<React.RefAttributes<THREE.Object3D>>;
  Plastic: React.ForwardRefExoticComponent<React.RefAttributes<THREE.Object3D>>;
  Yellow: React.ForwardRefExoticComponent<React.RefAttributes<THREE.Object3D>>;
}

interface PanelProps extends React.ComponentPropsWithoutRef<"group"> {
  meshes: PanelMeshes;
}

export default function Panel({ meshes, ...props }: PanelProps) {
  const { Metal, Plastic, Yellow } = meshes;

  // const { nodes, materials } = useGLTF(
  //   "/assets/models/Panel.glb",
  // ) as PanelGLTFResult;

  // materials.lightYellow.emissive = new THREE.Color(10, 10, 0);
  // materials.lightYellow.emissiveIntensity = 1;

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={100}>
        <Plastic />
        <Metal />
        <Yellow />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/Panel.glb");
