import City from "@components/models/City";
import React from "react";

export default function MatrixCity(props: React.ComponentProps<"group">) {
  return (
    <group {...props}>
      <City />
    </group>
  );
}
