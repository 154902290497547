import clsx from "clsx";
import React from "react";
import { Justification } from "../consts";

interface AnimatedParagraphProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  right?: boolean;
  justification?: Justification;
}

export default function AnimatedParagraph({
  right,
  justification = Justification.UP,
  children,
  className,
  ...props
}: AnimatedParagraphProps) {
  return (
    <div
      className={clsx(
        "flex h-svh w-full snap-start flex-col text-balance px-8 py-10",
        className,
      )}
      {...props}
    >
      <div className="h-[22svh]" />
      <div
        className={clsx(
          "flex h-[78svh] w-[21rem] flex-col md:w-[45rem] lg:w-[60rem]",
          right && "items-end self-end text-right",
          justification == Justification.UP && "justify-start",
          justification == Justification.MID && "justify-center",
          justification == Justification.BOT && "justify-end",
        )}
      >
        <span>{children}</span>
      </div>
    </div>
  );
}
