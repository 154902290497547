/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\Button.glb --types 
*/

import * as THREE from "three";
import React, { forwardRef, useCallback, useState } from "react";
import { meshBounds, useCursor, useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { SpringRef, a, useSpring } from "@react-spring/three";
import { ThreeEvent } from "@react-three/fiber";

const materials = {
  scale: new THREE.MeshStandardMaterial({ color: "white" }),
  stretch: new THREE.MeshStandardMaterial({ color: "white" }),
  metal: new THREE.MeshStandardMaterial({ color: "white" }),
};

const textures = {
  scale: "/assets/img/Scale.png",
  stretch: "/assets/img/Stretch.png",
  metal: "/assets/img/Metal.png",
};

useTexture.preload("/assets/img/Scale.png");
useTexture.preload("/assets/img/Stretch.png");
useTexture.preload("/assets/img/Metal.png");

type GLTFResult = GLTF & {
  nodes: {
    ButtonBase_BaseAndScrews_0: THREE.Mesh;
    ButtonTop_Base_0: THREE.Mesh;
    ["ButtonMid_Material_#45_0"]: THREE.Mesh;
    ["ButtonPress_Material_#45_0"]: THREE.Mesh;
    Cylinder001_Base_0: THREE.Mesh;
    Cylinder002_BaseAndScrews_0: THREE.Mesh;
    Cylinder003_BaseAndScrews_0: THREE.Mesh;
    Cylinder004_BaseAndScrews_0: THREE.Mesh;
    Cylinder005_BaseAndScrews_0: THREE.Mesh;
  };
  materials: {
    BaseAndScrews: THREE.MeshStandardMaterial;
    Base: THREE.MeshStandardMaterial;
    Material_45: THREE.MeshStandardMaterial;
  };
};

export interface ButtonRef {
  api: SpringRef<{
    posY: number;
  }>;
  start: number;
  end: number;
}

interface ButtonProps extends React.ComponentPropsWithoutRef<"group"> {
  type: keyof typeof materials;
}

const Button = forwardRef<ButtonRef, ButtonProps>(({ type, ...props }, ref) => {
  const model = useGLTF("/assets/models/Button.glb") as GLTFResult;

  const [hover, setHover] = useState(false);
  useCursor(hover);

  const material = materials[type];
  material.metalness = model.materials.Material_45.metalness;
  material.roughness = model.materials.Material_45.roughness;
  material.map = useTexture(textures[type]);

  const start = 14;
  const end = 17.717;

  const [{ posY }, api] = useSpring(() => ({ posY: end }));

  if (ref && typeof ref !== "function") ref.current = { api, start, end };

  const onEnter = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(true);
  }, []);
  const onExit = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(false);
  }, []);

  return (
    <group
      {...props}
      dispose={null}
      onPointerEnter={onEnter}
      onPointerLeave={onExit}
    >
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.ButtonBase_BaseAndScrews_0.geometry}
        material={model.materials.BaseAndScrews}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.ButtonTop_Base_0.geometry}
        material={model.materials.Base}
        position={[0, 5.906, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes["ButtonMid_Material_#45_0"].geometry}
        material={material}
        position={[0, 11.811, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <a.mesh
        geometry={model.nodes["ButtonPress_Material_#45_0"].geometry}
        material={material}
        position={posY.to((pos) => [0, pos, 0])}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.Cylinder001_Base_0.geometry}
        material={model.materials.Base}
        position={[0, 11.811, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.Cylinder002_BaseAndScrews_0.geometry}
        material={model.materials.BaseAndScrews}
        position={[-6.693, 10.433, 6.693]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.Cylinder003_BaseAndScrews_0.geometry}
        material={model.materials.BaseAndScrews}
        position={[-6.693, 10.433, -6.693]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.Cylinder004_BaseAndScrews_0.geometry}
        material={model.materials.BaseAndScrews}
        position={[6.693, 10.433, -6.693]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        raycast={meshBounds}
        geometry={model.nodes.Cylinder005_BaseAndScrews_0.geometry}
        material={model.materials.BaseAndScrews}
        position={[6.693, 10.433, 6.693]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  );
});

Button.displayName = "Button";

export default Button;

useGLTF.preload("/assets/models/Button.glb");
