/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\Window.glb --types 
Author: Sousinho (https://sketchfab.com/sousinho)
License: SKETCHFAB Standard (https://sketchfab.com/licenses)
Source: https://sketchfab.com/3d-models/ceiling-window-and-frame-7eaba7ae05ed474dae0c691bf2c45e07
Title: Ceiling window and frame
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

export type WindowGLTFResult = GLTF & {
  nodes: {
    SM_ceiling_window_T_ceiling_window_frame_0: THREE.Mesh;
    SM_ceiling_window_T_ceiling_windows_0: THREE.Mesh;
  };
  materials: {
    T_ceiling_window_frame: THREE.MeshStandardMaterial;
    T_ceiling_windows: THREE.MeshStandardMaterial;
  };
};

export interface WindowMeshes {
  Base: React.ForwardRefExoticComponent<React.RefAttributes<THREE.Object3D>>;
  Frame: React.ForwardRefExoticComponent<React.RefAttributes<THREE.Object3D>>;
}

interface WindowProps extends React.ComponentPropsWithoutRef<"group"> {
  meshes: WindowMeshes;
}

export default function Window({ meshes, ...props }: WindowProps) {
  const { Base, Frame } = meshes;

  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <group
          position={[-325.742, 349.585, 305]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        >
          <Frame />
          <Base />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/Window.glb");
