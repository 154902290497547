import React from "react";
import { a, useScroll } from "@react-spring/three";
import { useThree } from "@react-three/fiber";
import { useControls } from "leva";

export default function Wrapper({ children }: { children: React.ReactNode[] }) {
  const { viewport } = useThree();

  const { movementFactor } = useControls("3d Spacing", {
    movementFactor: { value: 1.2, min: 0.1, max: 2 },
  });

  const { scrollYProgress } = useScroll({
    reverse: true,
    immediate: true,
    config: { duration: 0 },
  });

  return children.map((child, index) => (
    <a.group
      key={index}
      position-y={scrollYProgress.to(
        (prog) =>
          (prog * viewport.height * 5 - viewport.height * index) *
          movementFactor,
      )}
    >
      {child}
    </a.group>
  ));
}
