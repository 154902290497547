/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\eggCooked.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_eggCooked: THREE.Mesh;
    Mesh_eggCooked_1: THREE.Mesh;
  };
  materials: {
    _defaultMat: THREE.MeshBasicMaterial;
    yellow: THREE.MeshBasicMaterial;
  };
};

export function EggCooked(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/eggCooked.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Mesh_eggCooked.geometry}
        material={materials._defaultMat}
      />
      <mesh
        geometry={nodes.Mesh_eggCooked_1.geometry}
        material={materials.yellow}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/food/eggCooked.glb");
