import { useControls } from "leva";
import Button, { ButtonRef } from "../models/Button";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useCallback, useRef, useState } from "react";
import { a, useSpring } from "@react-spring/three";
import { Float } from "@react-three/drei";

interface SpringInterface {
  scale: [x: number, y: number, z: number];
  metal: number;
}

export default function Laboratory() {
  const { spaceAmount, maxSpace } = useControls("Laboratory", {
    spaceAmount: { value: 0.0028, min: 0.001, max: 0.01 },
    maxSpace: { value: 2, min: 0.1, max: 3 },
  });

  const { size } = useThree();

  const space = Math.min(maxSpace, spaceAmount * size.width);

  const scaleRef = useRef<ButtonRef>(null!);
  const stretchRef = useRef<ButtonRef>(null!);
  const metalRef = useRef<ButtonRef>(null!);

  const [scale, setScale] = useState(false);
  const [stretch, setStretch] = useState(false);
  const [metal, setMetal] = useState(false);

  const spring = useSpring<SpringInterface>({
    scale: scale ? [stretch ? 3 : 1.5, 1.5, 1.5] : [stretch ? 2 : 1, 1, 1],
    metal: metal ? 1 : 0,
  });

  const buttonAnimation = useCallback(
    (buttonRef: ButtonRef) =>
      buttonRef.api.start({
        from: { posY: buttonRef.start },
        to: { posY: buttonRef.end },
      }),
    [],
  );

  const scaleCallback = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      setScale((state) => !state);
      void buttonAnimation(scaleRef.current);
    },
    [buttonAnimation],
  );
  const stretchCallback = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      setStretch((state) => !state);
      void buttonAnimation(stretchRef.current);
    },
    [buttonAnimation],
  );
  const metalCallback = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      setMetal((state) => !state);
      void buttonAnimation(metalRef.current);
    },
    [buttonAnimation],
  );

  return (
    <>
      <Float rotationIntensity={5}>
        <a.mesh scale={spring.scale}>
          <boxGeometry args={[1, 1, 1]} />
          <a.meshStandardMaterial
            color={"fuchsia"}
            metalness={spring.metal.to((met) => met * 0.8)}
            roughness={spring.metal.to((met) => 1 - met)}
          />
        </a.mesh>
      </Float>

      <group position-y={-3} rotation-x={0.6}>
        <Button
          type="scale"
          position-x={-space}
          scale={0.05}
          onPointerDown={scaleCallback}
          ref={scaleRef}
        />
        <Button
          type="stretch"
          scale={0.05}
          onPointerDown={stretchCallback}
          ref={stretchRef}
        />
        <Button
          type="metal"
          position-x={space}
          scale={0.05}
          onPointerDown={metalCallback}
          ref={metalRef}
        />
      </group>
    </>
  );
}
