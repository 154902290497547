import { Suspense, useEffect, useMemo, useRef } from "react";
import Panel, { PanelGLTFResult, PanelMeshes } from "../models/Panel";
import Window, { WindowGLTFResult, WindowMeshes } from "../models/Window";
import { Group } from "three";
import { useFrame } from "@react-three/fiber";
import React from "react";
import { Merged, meshBounds, useGLTF } from "@react-three/drei";

function Constructor({
  window,
  panel,
}: {
  window: WindowMeshes;
  panel: PanelMeshes;
}) {
  const floor = useMemo(() => {
    const floor = [];
    for (let x = -15; x < 15; x++) {
      for (let z = -1; z < 1; z++) {
        floor.push(
          <Panel
            meshes={panel}
            key={`Floor_${x}_${z}`}
            scale={0.01}
            position={[x * 2, 0, -2 * z]}
            rotation-x={-Math.PI / 2}
          />,
        );
      }
    }
    return floor;
  }, [panel]);

  const ceiling = useMemo(() => {
    const ceiling = [];
    for (let x = -15; x < 15; x++) {
      for (let z = -2; z < 0; z++) {
        ceiling.push(
          <Panel
            meshes={panel}
            key={`Ceiling_${x}_${z}`}
            scale={0.01}
            position={[x * 2, 4, -2 * z - 4]}
            rotation-x={Math.PI / 2}
          />,
        );
      }
    }
    return ceiling;
  }, [panel]);

  const wall = useMemo(() => {
    const wall = [];
    for (let x = -3; x < 3; x++) {
      for (let y = 0; y < 1; y++) {
        wall.push(
          <Window
            meshes={window}
            key={`Wall_${x}_${y}`}
            scale={1}
            position={[x * 8, y * 2 - 3, 2]}
            rotation-x={-Math.PI / 2}
          />,
        );
      }
    }
    return wall;
  }, [window]);
  return (
    <>
      {ceiling}
      {wall}
      <group position={[0, 0, 4]}>{wall}</group>
      {floor}
    </>
  );
}

const Corridor = React.memo(function Corridor() {
  const corridorRef = useRef<Group>(null!);

  useFrame(({ clock }) => {
    corridorRef.current.position.x = clock.elapsedTime % 5;
    //console.log(Math.abs(corridorRef.current.worldToLocal(camera.position).y));
  });

  useEffect(() => {
    corridorRef.current.traverse((elem) => {
      // if (elem instanceof Mesh && elem.geometry instanceof BufferGeometry) {
      //   elem.geometry.computeBoundingBox();
      // }
      elem.frustumCulled = false;
    });
  }, []);

  const window = useGLTF("/assets/models/Window.glb") as WindowGLTFResult;
  const panel = useGLTF("/assets/models/Panel.glb") as PanelGLTFResult;

  return (
    <Suspense>
      <Merged
        raycast={meshBounds}
        position={[0, -0.42, -0.1]}
        scale={0.91}
        ref={corridorRef}
        rotation-x={0.05}
        meshes={{
          Base: window.nodes.SM_ceiling_window_T_ceiling_window_frame_0,
          Frame: window.nodes.SM_ceiling_window_T_ceiling_windows_0,
          Yellow: panel.nodes.SciFi_Panel_001_lightYellow_0,
          Plastic: panel.nodes.SciFi_Panel_001_plasticBlack_0,
          Metal: panel.nodes.SciFi_Panel_001_metal_0,
        }}
      >
        {({
          Frame,
          Base,
          Yellow,
          Plastic,
          Metal,
        }: WindowMeshes & PanelMeshes) => (
          <group>
            <Constructor
              window={{ Frame, Base }}
              panel={{ Yellow, Plastic, Metal }}
            />
          </group>
        )}
      </Merged>
    </Suspense>
  );
});

//Corridor.displayName = "Corridor";

export default Corridor;
