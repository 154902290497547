/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\tomatoSlice.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_tomatoSlice: THREE.Mesh;
    Mesh_tomatoSlice_1: THREE.Mesh;
  };
  materials: {
    red: THREE.MeshBasicMaterial;
    redLight: THREE.MeshBasicMaterial;
  };
};

export function TomatoSlice(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/tomatoSlice.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Mesh_tomatoSlice.geometry}
        material={materials.red}
      />
      <mesh
        geometry={nodes.Mesh_tomatoSlice_1.geometry}
        material={materials.redLight}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/food/tomatoSlice.glb");
