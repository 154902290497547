import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { useCallback, useEffect, useMemo, useRef } from "react";

import vertexShader from "@components/materials/shaders/fireworks/vertex.glsl";
import fragmentShader from "@components/materials/shaders/fireworks/fragment.glsl";
import { useThree } from "@react-three/fiber";
import gsap from "gsap";

useTexture.preload("/assets/img/particles/1.png");
useTexture.preload("/assets/img/particles/2.png");
useTexture.preload("/assets/img/particles/3.png");
useTexture.preload("/assets/img/particles/4.png");
useTexture.preload("/assets/img/particles/5.png");
useTexture.preload("/assets/img/particles/6.png");
useTexture.preload("/assets/img/particles/7.png");
useTexture.preload("/assets/img/particles/8.png");

export default function Fireworks() {
  const particleGroupRef = useRef<THREE.Group>(null!);

  const resolution = useThree((three) => three.size);

  const tex1 = useTexture("/assets/img/particles/1.png");
  const tex2 = useTexture("/assets/img/particles/2.png");
  const tex3 = useTexture("/assets/img/particles/3.png");
  const tex4 = useTexture("/assets/img/particles/4.png");
  const tex5 = useTexture("/assets/img/particles/5.png");
  const tex6 = useTexture("/assets/img/particles/6.png");
  const tex7 = useTexture("/assets/img/particles/7.png");
  const tex8 = useTexture("/assets/img/particles/8.png");

  const textures = useMemo(
    () => [tex1, tex2, tex3, tex4, tex5, tex6, tex7, tex8],
    [tex1, tex2, tex3, tex4, tex5, tex6, tex7, tex8],
  );

  const createFirework = useCallback(
    (
      count: number,
      position: THREE.Vector3,
      size: number,
      texture: THREE.Texture,
      radius: number,
      color: THREE.Color,
    ) => {
      const positionArray = new Float32Array(count * 3);
      const sizesArray = new Float32Array(count);
      const lifeTimeArray = new Float32Array(count);

      for (let i = 0; i < count; i++) {
        const i3 = i * 3;

        const spherical = new THREE.Spherical(
          radius * (0.75 + Math.random() * 0.25),
          Math.random() * Math.PI,
          Math.random() * Math.PI * 2,
        );

        const position = new THREE.Vector3();
        position.setFromSpherical(spherical);

        positionArray[i3] = position.x;
        positionArray[i3 + 1] = position.y;
        positionArray[i3 + 2] = position.z;

        sizesArray[i] = Math.random();
        lifeTimeArray[i] = 1 + Math.random();
      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positionArray, 3),
      );
      geometry.setAttribute("aSize", new THREE.BufferAttribute(sizesArray, 1));
      geometry.setAttribute(
        "aLifeTime",
        new THREE.BufferAttribute(lifeTimeArray, 1),
      );

      texture.flipY = false;

      const material = new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms: {
          uResolution: new THREE.Uniform(
            new THREE.Vector2(resolution.width, resolution.height),
          ),
          uSize: new THREE.Uniform(size),
          uTexture: new THREE.Uniform(texture),
          uColor: new THREE.Uniform(color),
          uProgress: new THREE.Uniform(0),
        },
        depthWrite: false,
        transparent: true,
        blending: THREE.AdditiveBlending,
      });

      const points = new THREE.Points(geometry, material);
      points.position.copy(position);

      particleGroupRef.current.add(points);

      gsap.to(material.uniforms.uProgress, {
        value: 1,
        duration: 3,
        ease: "none",
        onComplete: () => {
          particleGroupRef.current.remove(points);
          geometry.dispose();
          material.dispose();
        },
      });
    },
    [resolution.height, resolution.width, particleGroupRef],
  );

  const createRandomFirework = useCallback(() => {
    const color = new THREE.Color();
    color.setHSL(Math.random(), 1, 0.7);
    createFirework(
      Math.round(400 + Math.random() * 1000),
      new THREE.Vector3(
        (Math.random() - 0.5) * 10,
        Math.random() * 1.5,
        (Math.random() - 0.5) * 3 - 1,
      ), //Position
      0.1 + Math.random() * 0.1,
      textures[Math.floor(Math.random() * textures.length)],
      0.5 + Math.random(),
      color,
    );
  }, [createFirework, textures]);

  const generateFireworks = useCallback(
    (prop: { timeout: NodeJS.Timeout | undefined }) => {
      prop.timeout = setTimeout(
        () => {
          createRandomFirework();
          generateFireworks(prop);
        },
        Math.random() * 500 + 50,
      );
    },
    [createRandomFirework],
  );

  useEffect(() => {
    const prop = { timeout: undefined };
    generateFireworks(prop);
    return () => clearTimeout(prop.timeout);
  }, [generateFireworks]);

  return <group ref={particleGroupRef} position-y={0} />;
}
