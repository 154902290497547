import MatrixCity from "@components/three/MatrixCity";
import TicTacToe from "@components/three/TicTacToe";
import Corridor from "./Corridor";
//import PlayBox from "./PlayBox";
// import Configurator from "./Configurator";
import Fireworks from "./Fireworks";
import Wrapper from "./Wrapper";
import { AdaptiveDpr, Environment } from "@react-three/drei";
//import Rifle from "./Rifle";
import Laboratory from "./Laboratory";
import {
  Bloom,
  EffectComposer,
  ToneMapping,
} from "@react-three/postprocessing";
import { ToneMappingMode } from "postprocessing";
import FoodConfigurator from "./FoodConfigurator";

// import { useFrame } from "@react-three/fiber";
// import { useControls } from "leva";
// import { Vector3 } from "three";
// import { useMemo } from "react";
//import PlayBox from "./PlayBox";

export default function Experience() {
  // const target = useMemo(() => new Vector3(0, 0, 0), []);

  // const { pointerxAmount, pointerYAmount } = useControls("pointer", {
  //   pointerxAmount: { value: 0.1, min: 0, max: 1 },
  //   pointerYAmount: { value: 0.1, min: 0, max: 1 },
  // });

  // useFrame(({ size, camera, pointer }) => {
  //   if (size.width < 768) return;

  //   //camera.lookAt(target);
  //   camera.position.set(
  //     pointer.x * pointerxAmount,
  //     pointer.y * pointerYAmount,
  //     camera.position.z,
  //   );
  // });

  return (
    <>
      <Environment preset="studio" environmentIntensity={0.5} />
      <AdaptiveDpr pixelated />

      <Wrapper>
        <MatrixCity
          position-y={-1}
          position-z={2.5}
          scale={0.5}
          rotation-x={Math.PI * 0.5 * 0.3}
        />
        <TicTacToe />
        <Corridor />
        {/* <PlayBox /> */}
        {/* <Rifle /> */}
        <Laboratory />
        {/* <Configurator /> */}
        <FoodConfigurator />
        <Fireworks />
      </Wrapper>

      <EffectComposer>
        <Bloom luminanceThreshold={1.1} intensity={0.15} />
        <ToneMapping mode={ToneMappingMode.REINHARD} />
      </EffectComposer>
    </>
  );
}
