import { a, useSpring } from "@react-spring/three";
import { BoxGeometry } from "three";

const box = new BoxGeometry(0.2, 1.2, 0.2);

interface CrossProps extends React.ComponentPropsWithoutRef<"group"> {
  color: string;
}

export default function Cross({ color, ...props }: CrossProps) {
  const { sColor } = useSpring({
    sColor: color,
  });

  const { scale } = useSpring({
    from: { scale: 1.2 },
    to: { scale: 1 },
  });

  return (
    <a.group scale={scale} {...props}>
      <mesh geometry={box} position-z={0} rotation-z={Math.PI / 4}>
        <a.meshBasicMaterial color={sColor} />
      </mesh>
      <mesh geometry={box} position-z={0} rotation-z={-Math.PI / 4}>
        <a.meshBasicMaterial color={sColor} />
      </mesh>
    </a.group>
  );
}
