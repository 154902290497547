/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 .\mushroomHalf.glb --types 
*/

import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_mushroomHalf: THREE.Mesh;
    Mesh_mushroomHalf_1: THREE.Mesh;
  };
  materials: {
    brownDark: THREE.MeshBasicMaterial;
    brownLight: THREE.MeshBasicMaterial;
  };
};

export function MushroomHalf(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "/assets/models/food/mushroomHalf.glb",
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Mesh_mushroomHalf.geometry}
        material={materials.brownDark}
      />
      <mesh
        geometry={nodes.Mesh_mushroomHalf_1.geometry}
        material={materials.brownLight}
      />
    </group>
  );
}

useGLTF.preload("/assets/models/food/mushroomHalf.glb");
