import useFood from "@/stores/useFood";
import { Trans } from "react-i18next";
import { FaArrowLeft, FaArrowRight, FaPlus, FaMinus } from "react-icons/fa";

export default function ConfiguratorScreen() {
  const amount = useFood((state) => state.amount);
  const next = useFood((state) => state.next);
  const previous = useFood((state) => state.previous);
  const addFood = useFood((state) => state.addFood);
  const removeFood = useFood((state) => state.removeFood);

  return (
    <div
      className={
        "relative flex h-svh w-full snap-start flex-col text-balance px-8 py-10"
      }
    >
      <div className="h-[20svh]" />
      <div
        className={
          "flex h-[80svh] w-[18rem] flex-col items-end justify-start self-end text-right md:w-[45rem] lg:w-[60rem]"
        }
      >
        <span>
          <Trans i18nKey={"life desc"} />
        </span>
      </div>
      <div className="absolute left-0 top-[42lvh] flex h-44 w-full items-center justify-center">
        <div className="z-20 flex w-full flex-col items-center">
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/}
          {[...Array(amount)].map((_, index) => (
            <div
              key={index}
              className="flex w-[90%] flex-row justify-between md:w-[50%] lg:w-[40%]"
            >
              <div
                onClick={() => previous(index)}
                className="m-1 w-fit cursor-pointer rounded-xl border-2 border-fuchsia-700 p-2 text-fuchsia-700"
              >
                <FaArrowLeft />
              </div>
              <div
                onClick={() => next(index)}
                className="m-1 w-fit cursor-pointer rounded-xl border-2 border-fuchsia-700 p-2 text-fuchsia-700"
              >
                <FaArrowRight />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute bottom-10 right-5 z-20 flex flex-col md:right-12">
        <button
          className="m-1 rounded-xl border-2 border-fuchsia-700 p-2 text-fuchsia-700 md:m-4"
          onClick={() => addFood()}
        >
          <FaPlus className="h-8 w-8 md:h-12 md:w-12" />
        </button>
        <button
          className="m-1 rounded-xl border-2 border-fuchsia-700 p-2 text-fuchsia-700 md:m-4"
          onClick={removeFood}
        >
          <FaMinus className="h-8 w-8 md:h-12 md:w-12" />
        </button>
      </div>
    </div>
  );
}
