attribute float aSize;
attribute float aLifeTime;

uniform vec2 uResolution;
uniform float uSize;
uniform float uProgress;

float remap(float value, float originalMin, float originalMax, float destinationMin, float destinationMax){
	float ret = destinationMin + (value - originalMin) * (destinationMax - destinationMin) / (originalMax - originalMin);
	float mn = min(destinationMin, destinationMax);
	float mx = max(destinationMin, destinationMax);
	return clamp(ret, mn, mx);
}

void main(){
	vec3 newPosition = position;
	float progress = uProgress * aLifeTime;

	//EXPLOSION
	float explosion = remap(progress, 0.0, 0.1, 0.0, 1.0);
	newPosition *= 1.0 - pow(1.0 - explosion, 5.0);

	//FALLING
	float falling = remap(progress, 0.1, 1.0, 0.0, 1.0);
	newPosition.y -= falling * 0.2;

	//SCALING
	float scaling = min(remap(progress, 0.0, 0.125, 0.0, 1.0), remap(progress, 0.125, 1.0, 1.0, 0.0));

	//TWINKLING
	float twinkling = remap(progress, 0.2, 0.8, 0.0, 1.0);
	float twinklingSize = sin(progress * 30.0) * 0.5 + 0.5;
	twinklingSize = 1.0 - twinklingSize * twinkling;


	vec4 modelPosition = modelMatrix * vec4(newPosition, 1.0);
	vec4 viewPosition = viewMatrix * modelPosition;

	gl_Position = projectionMatrix * viewPosition;
	gl_PointSize = (uSize * uResolution.y * aSize * scaling * twinklingSize) * 1.0 / - viewPosition.z;

	if(gl_PointSize < 1.0)
		gl_Position = vec4(9999.9);
}