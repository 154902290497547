import React from "react";
import Rotator from "../models/Rotator";

export default function FoodWrapper(props: JSX.IntrinsicElements["group"]) {
  const total = React.Children.count(props.children);

  return (
    <group {...props}>
      {React.Children.map<React.ReactNode, React.ReactNode>(
        props.children,
        (child, index) => (
          <Rotator index={index + 1} total={total} key={index + 1}>
            {child}
          </Rotator>
        ),
      )}
    </group>
  );
}
