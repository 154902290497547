import useTicTacToe from "@/stores/useTicTacToe";
import { useMemo } from "react";
import { BoxGeometry } from "three";
import Circle from "./Circle";
import Cross from "./Cross";
import { useControls } from "leva";
import EmptyBoard from "./EmptyBoard";
import { a, useSpring } from "@react-spring/three";

// interface SpringProp {
//   rotation: [x: number, y: number, z: number, order?: EulerOrder | undefined];
// }

export default function TicTacToe() {
  const box = useMemo(() => new BoxGeometry(0.1, 1.5, 0.1), []);

  const board = useTicTacToe((state) => state.board);
  const phase = useTicTacToe((state) => state.phase);
  const winningPieces = useTicTacToe((state) => state.winningPlaces);
  const lastWinner = useTicTacToe((state) => state.lastWinner);

  const { space, pieceSpace } = useControls("TicTacToe", {
    space: { value: 0.3, min: 0.01, max: 0.5 },
    pieceSpace: { value: 2, min: 1.2, max: 5 },
  });

  const { rotationX, rotationY } = useSpring({
    // rotation: winningPieces.length > 0 ? [1, 1, 0] : [0, 0, 0],
    rotationX: winningPieces.length > 0 ? 0.3 : 0,
    rotationY: winningPieces.length > 0 ? 0.5 : 0,
  });

  const grid = useMemo(() => {
    const grid = [];
    for (let x = 0; x < 3; x++) {
      for (let y = 0; y < 3; y++) {
        let color = "white";
        for (const piece of winningPieces) {
          if (piece.x === x && piece.y === y)
            // color = lastWinner === "player" ? "green" : "red";
            color = lastWinner === "player" ? "fuchsia" : "yellow";
        }

        switch (board[x][y]) {
          case "o":
            grid.push(
              <Circle
                color={color}
                position={[(x - 1) * pieceSpace, (y - 1) * pieceSpace, 0]}
                key={`Piece_${x}_${y}`}
              />,
            );
            break;
          case "x":
            grid.push(
              <Cross
                color={color}
                position={[(x - 1) * pieceSpace, (y - 1) * pieceSpace, 0]}
                key={`Piece_${x}_${y}`}
              />,
            );
            break;
          default:
            grid.push(
              <EmptyBoard
                position={[(x - 1) * pieceSpace, (y - 1) * pieceSpace, 0]}
                x={x}
                y={y}
                key={`Piece_${x}_${y}`}
              />,
            );
            break;
        }
      }
    }
    return grid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, pieceSpace]);

  return (
    <a.group
      scale={0.7}
      position={[0, -0.35, 3]}
      rotation-x={rotationX}
      rotation-y={rotationY}
    >
      <mesh position-x={[-space]} geometry={box} />
      <mesh position-x={[space]} geometry={box} />
      <mesh position-y={[-space]} rotation-z={[Math.PI / 2]} geometry={box} />
      <mesh position-y={[space]} rotation-z={[Math.PI / 2]} geometry={box} />
      <group scale={0.3}>{grid}</group>
    </a.group>
  );
}
