import { Trans } from "react-i18next";
import Footer from "./Footer";

export default function LastScreen() {
  return (
    <div className="flex h-svh snap-start flex-col text-balance">
      <div className="relative flex flex-grow flex-col items-center">
        <div className="h-[20svh]" />
        <div className="relative w-fit text-6xl lg:text-8xl">
          <div className="absolute w-fit blur-md">
            <strong className="">
              <Trans i18nKey={"amaze"} />
            </strong>
          </div>
          <div className="prose-strong:text-fuchsia-200">
            <strong className="">
              <Trans i18nKey={"amaze"} />
            </strong>
          </div>
        </div>
        <div className="relative -top-1 w-fit text-2xl md:-top-4 lg:text-4xl">
          <Trans i18nKey={"your customers"} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
