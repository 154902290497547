import { a, useSpring } from "@react-spring/three";
import React from "react";
import { TorusGeometry } from "three";

const torus = new TorusGeometry(0.5, 0.15, 32, 32);

interface CircleProps extends React.ComponentPropsWithoutRef<"mesh"> {
  color: string;
}

export default function Circle({ color, ...props }: CircleProps) {
  const { sColor } = useSpring({
    sColor: color,
  });
  const { scale } = useSpring({
    from: { scale: 1.2 },
    to: { scale: 1 },
  });

  return (
    <a.mesh {...props} scale={scale} geometry={torus}>
      <a.meshBasicMaterial color={sColor} />
    </a.mesh>
  );
}
