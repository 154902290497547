import useTicTacToe from "@/stores/useTicTacToe";
import { meshBounds } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import React, { useCallback } from "react";

interface EmptyBoardProps extends React.ComponentPropsWithoutRef<"mesh"> {
  x: number;
  y: number;
}

export default function EmptyBoard({ x, y, ...props }: EmptyBoardProps) {
  const select = useTicTacToe((state) => state.select);

  const pointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      select(x, y);
    },
    [x, y, select],
  );

  return (
    <mesh
      visible={false}
      {...props}
      onPointerDown={pointerDown}
      raycast={meshBounds}
    >
      <boxGeometry />
      <meshBasicMaterial color={"red"} />
    </mesh>
  );
}
